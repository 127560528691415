import React from 'react'
import { graphql } from 'gatsby'
import tw, { css, styled } from 'twin.macro'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import SeoPlugin from 'gatsby-plugin-wpgraphql-seo'
import Container from '../components/Container'
import Wysiwyg from '../components/Wysiwyg'
import Seo from '../components/Seo'

const TeamPage = ({ data: { wpTeam: data } }) => {
  const articleImage = getImage(data?.featuredImage?.node.gatsbyImage)
  return (
    <>
      {/* <Seo title={data?.seo?.title} description={data?.seo?.metaDesc} /> */}
      <SeoPlugin post={data} title={data?.seo?.title} description={data?.seo?.metaDesc} />
      <Container variant="wide" tw="py-10 tablet:py-5">
        <div tw="flex tablet:flex-col">
          {data?.featuredImage && (
            <GatsbyImage
              tw="rounded-3xl mb-5 max-w-[450px] mr-10 tablet:mr-0"
              width="450"
              hight="253"
              image={articleImage}
              alt={data.featuredImage?.node.altText}
              imgStyle={{ borderRadius: `1.5rem` }}
            />
          )}
          <div tw="my-auto items-center">
            <h2>{data?.title}</h2>
            <span>{data?.team?.role}</span>
          </div>
        </div>
        <Wysiwyg tw="pt-10">{data?.content}</Wysiwyg>
      </Container>
    </>
  )
}

export default TeamPage

export const pageQuery = graphql`
  query ($id: String!) {
    wpTeam(id: { eq: $id }) {
      id
      content
      team {
        role
      }
      title
      featuredImage {
        node {
          altText
          gatsbyImage(placeholder: BLURRED, formats: [AUTO, WEBP], width: 900)
        }
      }
      seo {
        title
        metaDesc
        readingTime
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
        breadcrumbs {
          text
          url
        }
      }
    }
  }
`
